<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="MainInfo">
    <div>
      <div class="vx-row w-full">
        <div class="vx-col w-full lg:w-2/3">
          <div class="vx-row w-full">
            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("NewsHeasline") }}</label>
              <vs-input
                v-model="NewsModel.NewsHeaslineAr"
                class="w-full"
                :data-vv-as="$t('NewsHeasline')"
                name="NewsHeasline"
                v-validate="'required'"
              />
            </div>

            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("NewsHeaslineEn") }}</label>
              <vs-input
                v-model="NewsModel.NewsHeaslineEn"
                class="w-full"
                :data-vv-as="$t('NewsHeaslineEn')"
                name="NewsHeaslineEn"
                v-validate="'required'"
              />
            </div>

            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("AuthorNameAr") }}</label>
              <vs-input
                v-model="NewsModel.AuthorNameAr"
                class="w-full"
                :data-vv-as="$t('AuthorNameAr')"
                name="AuthorNameAr"
                v-validate="'required'"
              />
            </div>

            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("AuthorNameEn") }}</label>
              <vs-input
                v-model="NewsModel.AuthorNameEn"
                class="w-full"
                :data-vv-as="$t('AuthorNameEn')"
                name="AuthorNameEn"
                v-validate="'required'"
              />
            </div>
            
            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("NewsDate") }}</label>
            <datepicker
            placeholder="NewsDate"
            v-model="NewsModel.NewsDate"
            class="w-full"
            name="from"
          ></datepicker>
            </div>

          </div>

          <div class="vx-row w-full">
            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("NewsContentEn") }}</label>
              <vs-textarea
                v-model="NewsModel.NewsContentEn"
                style="height: 8rem; overflow-x: auto !important" rows="10"
                class="w-full"
                :data-vv-as="$t('NewsContentEn')"
                name="NewsContentEn"
                v-validate="'required'"
              >
              </vs-textarea>
            </div>

            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("NewsContentAr") }}</label>
              <vs-textarea
                v-model="NewsModel.NewsContentAr"
                style="height: 8rem; overflow-x: auto !important" rows="10"
                class="w-full"
                name="NewsContentAr"
                :data-vv-as="$t('NewsContentAr')"
                v-validate="'required'"
              >
              </vs-textarea>
            </div>


          </div>
        </div>

        <div class="vx-row w-full lg:w-1/3">
          <vs-card title="icon">
            <h4>{{ $t("MainImg") }}</h4>
            <template v-if="NewsModel.MainImgURL">
              <div
                class="img-container w-64 mx-auto flex items-center justify-center"
              >
                <img
                  :src="baseURL + NewsModel.MainImgURL"
                  class="responsive"
                />
              </div>
            </template>

            <div class="modify-img flex justify-between mt-5">
              <input
                type="file"
                class="hidden"
                ref="updateImgInput"
                @change="updateCurrImg"
                accept="image/*"
              />
              <vs-button
                class="mr-4"
                type="flat"
                @click="$refs.updateImgInput.click()"
                >{{ $t("UpdateImage") }}</vs-button
              >
              <vs-button
                type="flat"
                color="#999"
                @click="NewsModel.MainImgURL = null"
                >{{ $t("RemoveImage") }}</vs-button
              >
            </div>
          </vs-card>
        </div>


      <!-- gallary -->
      <div class="vx-col lg:w-3/4 w-full mt-3">
        <div>
          <p class="text-align-left">{{ $t("NewsGalleries") }}</p>
        </div>
        <div class="vx-row w-full ml-5 mr-5 mb-3">
          <div
            v-for="item in NewsModel.NewsGalleries"
            :key="item.ID"
            class="lg:w-1/4 w-full"
          >
            <div>
              <div style="position: absolute">
                <feather-icon
                  icon="XIcon"
                  style="color: red"
                  svgClasses="w-10 h-10 hover:text-primary stroke-current"
                  @click.stop="removeImage(item)"
                />
              </div>
              <div>
                <img
                  style="border-radius: 10px; opacity: 1"
                  class="p-1"
                  :src="baseURL + item.ImgURL"
                  width="200"
                  height="200"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <input
            type="file"
            ref="uploadImgInput"
            @change="AttachmentUploaded"
            accept="image/*"
            @on-success="successUpload"
          />
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import Datepicker from "vuejs-datepicker";

export default {
  
  components: {
    Datepicker
  },
  props: {
    NewsModel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseURL: domain,
      isMail: false,
      DownUrl: domain,
      Attachment: null,
      update: false,
    };
  },
  computed: {},
  methods: {

    async updateCurrImg(input) {
      this.$vs.loading();
      debugger;
      if (input.target.files && input.target.files[0]) {
        const file = input.target.files[0];
        this.$store
          .dispatch("NewsList/UploadImage", file)
          .then((res) => {
            if (res.status == 200) {
              this.NewsModel.MainImgURL = res.data;
              this.$vs.loading.close();
              window.showSuccess();
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
            window.showError();
          });
      }
    },

    removeImage(item) {
      var index = this.NewsModel.NewsGalleries.findIndex(
        (x) => x.AttachPath == item.AttachPath
      );
      this.NewsModel.NewsGalleries.splice(index, 1);
      if (item.ID > 0) this.$store.dispatch("NewslList/DeleteAttachmenByID", item.ID);
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },

    AttachmentUploaded(input) {
      debugger;
      this.$vs.loading();
      debugger;
      if (input.target.files && input.target.files[0]) {
        const file = input.target.files[0];
        this.$store
          .dispatch("NewsList/UploadImage", file)
          .then((res) => {
            if (res.status == 200) {
           var temp = {};
           temp.ImgURL = res.data;
           this.NewsModel.NewsGalleries.push(temp);
              this.$vs.loading.close();
              window.showSuccess();
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
            window.showError();
          });
      }
    },


  },
  created() {},
};
</script>
