<template>
  <div id="page-user-edit">
    <vs-alert
      color="danger"
      title="User Not Found"
      :active.sync="user_not_found"
    >
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span
        ><router-link
          :to="{ name: 'page-user-list' }"
          class="text-inherit underline"
          >All News</router-link
        >
      </span>
    </vs-alert>

    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs class="tab-action-btn-fill-conatiner">
          <vs-tab :label="$t('MainInfo')" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <MainInfo class="mt-4" :NewsModel="NewsModel" />
            </div>
          </vs-tab>

          <!-- Save & Reset Button -->
          <div class="vx-row mb-5">
            <div class="vx-col w-full">
              <div class="mt-8 flex flex-wrap items-center justify-end">
                <vs-button
                  class="ml-4 mt-2"
                  @click="save_changes"
                  >{{ $t("Save") }}
                </vs-button>
                <vs-button
                  class="ml-4 mt-2"
                  type="border"
                  color="danger"
                  @click="cancle"
                  >{{ $t("Cancel") }}</vs-button
                >
              </div>
            </div>
          </div>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>
<script>
import MainInfo from "./MainInfo.vue";
import moduleNews from "@/store/News/moduleNews.js";

export default {
  components: {
    MainInfo,
  },
  computed: {},
  data() {
    return {
      NewsModel: {
        ApplicationUser:{},
      },
      user_not_found: false,
      activeTab: 0,
    };
  },
  methods: {
    reset_data() {
      this.initValues();
    },
    cancle() {
      this.$router.push("/News");
    },
    initValues() {
      this.NewsModel = {};
    },

    save_changes() {
      debugger;
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.NewsModel.ID !== null && this.NewsModel.ID >= 0) {
            this.$store
              .dispatch("NewsList/UpdateNews", this.NewsModel)
              .then(() => {
                window.showUpdateSuccess();
                this.$vs.loading.close();
                this.initValues();
                this.cancle();
              })
              .catch((err) => {
                if (err.response.status == 400) {
                  window.showUpdateFailed(err.response.data.message);
                } else {
                  window.showUpdateFailed("");
                }
                this.$vs.loading.close();
              });
          } else {
            this.$store
              .dispatch("NewsList/AddNews", this.NewsModel)
              .then(() => {
                window.showAddSuccess();
                this.initValues();
                this.$vs.loading.close();
                this.cancle();
              })
              .catch((err) => {
                if (err.response.status == 400) {
                  window.showAddFailed(err.response.data.message);
                } else {
                  window.showAddFailed("");
                }
                this.$vs.loading.close();
              });
          }
        }
      });
    },
  },
  created() {
    if (!moduleNews.isRegistered) {
      this.$store.registerModule("NewsList", moduleNews);
      moduleNews.isRegistered = true;
    }

    const ID = this.$route.params.ID;
    debugger;
    if (ID != undefined) {
      this.$store.dispatch("NewsList/GetNews", ID).then((res) => {
        this.NewsModel = res.data.data;
      });
    }
  },
};
</script>
