/*=========================================================================================
  File Name: moduleVideoMutations.js
  Description: Video Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.News.unshift(item)
  },
  SET_News(state, News) {
    state.News = News
  },
  UPDATE_News(state, News) {
    const NewsIndex = state.News.findIndex((p) => p.ID == News.ID)
    Object.assign(state.News[NewsIndex], News)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.News.findIndex((p) => p.ID == itemId)
    state.News.splice(ItemIndex, 1)
},
}
